<template>
  <div>
    <v-dialog
      v-model="isOpen"
      width="700"
      persistent
      scrollable
      :fullscreen="$vuetify.breakpoint.smAndDown"
      content-class="rounded-lg"
    >
      <v-card class="h-full d-flex flex-column" style="max-height: 90dvh">
        <v-card-text class="pa-0 ma-0 pt-0">
          <vue-perfect-scrollbar
            :settings="{ suppressScrollX: true, wheelPropagation: false }"
            style="height: 100%"
          >
            <v-stepper v-model="step" class="elevation-0">
              <v-stepper-header class="elevation-0 mb-2" style="height: 50px">
                <template v-for="(stp, i) in steps">
                  <v-divider v-if="i" :key="`divider-` + i" />
                  <v-stepper-step
                    :key="`step-` + i"
                    :complete="step > i + 1"
                    :step="i + 1"
                    class="py-0"
                  >
                    {{ stp }}
                  </v-stepper-step>
                </template>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1" class="pt-0">
                  <div style="position: relative">
                    <scanner
                      @scan="onScan"
                      :disallowMethods="['nfc']"
                      :paused="loading || step !== 1 || !isOpen"
                    />
                  </div>
                </v-stepper-content>
                <v-stepper-content step="2" class="pt-0">
                  <v-card
                    class="d-flex gap-4 align-center pa-2 mb-4"
                    outlined
                    rounded="lg"
                  >
                    <v-img
                      v-if="device?.Model?.image"
                      :src="
                        (device?.acquirer &&
                          device?.Model?.imageVariants?.[device.acquirer]) ||
                        device?.Model?.image
                      "
                      max-height="55"
                      max-width="55"
                    />
                    <v-icon v-else color="primary" size="55">
                      mdi-cash-register
                    </v-icon>
                    <div>
                      <h6 class="mb-0">POS {{ device?.Model?.brand }}</h6>
                      SN: {{ device?.serialNumber }}
                    </div>
                  </v-card>

                  <place-search
                    v-model="address"
                    label="Localização"
                    outlined
                    class="mb-4"
                    :disabled="loading"
                  ></place-search>
                  <v-text-field
                    v-if="address"
                    v-model="address.name"
                    label="Nome"
                    outlined
                    dense
                    class="mt-4"
                    :rules="[
                      (v) => !!v || 'Name é obrigatório',
                      (v) =>
                        (v && v.length <= 30) ||
                        'Name deve ter menos de 30 caracteres',
                    ]"
                    :disabled="!address"
                  />

                  <h6 class="mb-0">Formas de pagamento</h6>
                  <div class="d-flex gap-4">
                    <v-checkbox
                      v-model="allowPaymentMethods"
                      label="Dinheiro"
                      value="money"
                      :disabled="loading"
                    />
                    <v-checkbox
                      v-model="allowPaymentMethods"
                      label="PIX"
                      value="pix"
                      :disabled="loading"
                    />
                    <v-checkbox
                      v-model="allowPaymentMethods"
                      label="Cartão de Crédito"
                      value="credit"
                      :disabled="loading"
                    />
                    <v-checkbox
                      v-model="allowPaymentMethods"
                      label="Cartão de Débito"
                      value="debit"
                      :disabled="loading"
                    />
                  </div>

                  <v-switch
                    v-if="hasPermission(524288)"
                    v-model="allowCourtesy"
                    :disabled="loading"
                    label="Permitir emitir cortesia"
                    class="mt-4"
                  />

                  <h6>Operadores</h6>

                  <!-- <v-alert color="info" dense text>
                    <small>
                      Os operadores que ainda não possuem uma conta precisarão
                      criar uma para usar o POS.
                    </small>
                  </v-alert> -->
                  <div v-for="(user, i) in users" :key="i" class="d-flex">
                    <v-form v-model="user.valid" class="flex-grow-1">
                      <div class="d-flex gap-2">
                        <phone-input
                          :phone="user.whatsapp"
                          @update:phone="(e) => setUsers(i, 'whatsapp', e)"
                          :ddi="user.ddi"
                          @update:ddi="(e) => setUsers(i, 'ddi', e)"
                          @valid="(v) => setUsers(i, 'validPhone', v)"
                          dense
                          outlined
                          class="flex-grow"
                          label="WhatsApp do operador"
                          :disabled="loading"
                        />
                        <v-text-field
                          v-model="user.name"
                          label="Nome"
                          outlined
                          dense
                          :rules="[(v) => !!v || 'Nome é obrigatório']"
                          :disabled="loading"
                        />
                        <v-text-field-simplemask
                          v-model="user.document"
                          label="CPF"
                          v-bind:properties="{
                            prefix: '',
                            suffix: '',
                            outlined: true,
                            placeholder: '999.999.999-99',
                            type: 'tel',
                            rules: cpfRules,
                            disabled: loading,
                            dense: true,
                          }"
                          v-bind:options="{
                            inputMask: '###.###.###-##',
                            outputMask: '###.###.###-##',
                            empty: '',
                            applyAfter: false,
                            alphanumeric: false,
                            lowerCase: false,
                          }"
                        />
                      </div>
                    </v-form>
                    <v-btn v-if="i === 0" @click="addUser" class="ml-2" icon>
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn v-else @click="removeUser(i)" class="ml-2" icon>
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </div>

                  <v-switch
                    v-model="requireClientRegistration"
                    :disabled="loading"
                    label="Requerer cadastro do cliente"
                    class="mt-2"
                    hide-details
                  />
                  <v-switch
                    v-model="noFee"
                    :disabled="loading"
                    label="Isentar taxas"
                    class="mt-2"
                    hide-details
                  />
                  <v-alert
                    v-if="noFee"
                    type="warning"
                    icon="mdi-currency-usd-off"
                    dense
                    text
                    class="mt-2"
                  >
                    Ao isentar taxas, não será nenhuma taxa do cliente.
                  </v-alert>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
            <v-alert v-if="error" type="error" class="mx-6 mb-0">
              {{ error }}
            </v-alert>
          </vue-perfect-scrollbar>
        </v-card-text>

        <v-card-actions class="d-flex align-end gap-1 justify-space-between">
          <v-btn text :disabled="loading" @click="close"> Cancelar </v-btn>
          <v-btn
            v-if="step > 1"
            color="success"
            :disabled="
              loading || !users.every((u) => u.valid && u.validPhone) || !address
            "
            @click="save"
          >
            Salvar
          </v-btn>
          <v-btn
            v-else
            color="primary"
            text
            :disabled="loading"
            @click="findSN"
            small
          >
            Usar número de série
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <FindPosSessionBySN @success="continueSN" />
  </div>
</template>

<script>
import validateCpf from "@/utils/validate-cpf";

import { mapGetters } from "vuex";
import POS from "@/services/admin/pos";
import Scanner from "../../../global/scanner/Scanner.vue";
import PlaceSearch from "../../../../views/global/PlaceSearch.vue";
import PhoneInput from "../../../global/PhoneInput.vue";
import FindPosSessionBySN from "./FindPosSessionBySN.vue";

const defaultUser = () => ({
  name: "",
  document: "",
  valid: false,
  ddi: 55,
  whatsapp: "",
  validPhone: false,
});

export default {
  components: {
    Scanner,
    PlaceSearch,
    PhoneInput,
    FindPosSessionBySN,
  },
  data: () => ({
    isOpen: false,
    loading: false,
    code: null,
    device: null,
    users: [],
    error: false,
    step: 1,
    address: null,
    requireClientRegistration: false,
    allowCourtesy: false,
    allowPaymentMethods: ["money", "pix", "credit", "debit"],
    noFee: false,
    cpfRules: [(v) => !v || validateCpf(v) || "CPF inválido"],
  }),

  methods: {
    open(code) {
      this.isOpen = true;
      this.loading = false;
      this.error = false;
      this.step = 1;
      this.users = [defaultUser()];
      this.allowPaymentMethods = ["money", "pix", "credit", "debit"];
      this.noFee = false;
      this.address = null;
      if (code) {
        this.verifyCode(code);
      }
    },
    close() {
      this.isOpen = false;
      this.loading = false;
      this.error = false;
      this.step = 1;
      this.address = null;
      this.requireClientRegistration = false;
      this.users = [defaultUser()];
      this.allowCourtesy = false;
      this.allowPaymentMethods = ["money", "pix", "credit", "debit"];
    },
    findSN() {
      this.$root.$emit("find-pos-session-by-sn");
    },
    setUsers(index, key, value) {
      this.users[index][key] = value;
    },
    removeUser(index) {
      this.users.splice(index, 1);
    },

    addUser() {
      this.users.push(defaultUser());
    },
    async verifyCode(code) {
      try {
        this.loading = true;
        this.error = false;
        const { device } = await POS.adopt.verifyCode(
          this.selectedOrganization.id,
          code
        );

        this.code = code;
        this.device = device;
        this.step++;
      } catch (e) {
        this.error = e.message;
      } finally {
        this.loading = false;
      }
    },
    continueSN({ code, ...session }) {
      this.device = session.Device;
      this.code = code;
      this.step++;
    },
    onScan({ value }) {
      console.log("Scanned", value);
      const hostname = window.location.hostname;
      if (!value.includes(hostname)) return alert("Código inválido");
      const regex =
        /code=([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}:[0-9a-fA-F]{32})/;

      const match = value.match(regex);
      if (match && match[1]) {
        const code = match[1];
        this.verifyCode(code);
      } else {
        // Se nenhuma correspondência for encontrada, uma mensagem é exibida
        alert("Código não encontrado ou inválido.");
      }
    },

    async save() {
      try {
        this.loading = true;
        this.error = false;
        let orgId = this.selectedOrganization.id;

        const users = this.users.map((u) => ({
          name: u.name,
          document: u.document,
          ddi: u.ddi,
          phone: u.whatsapp,
        }));

        const paymentMethods = this.allowPaymentMethods;
        if (this.allowCourtesy) paymentMethods.push("courtesy");

        await POS.adopt.adopt(orgId, {
          code: this.code,
          address: this.address,
          users,
          allowPaymentMethods: paymentMethods,
          requireClientRegistration: this.requireClientRegistration,
          noFee: this.noFee,
        });

        this.$emit("success");
        this.close();
      } catch (e) {
        this.error = e.message;
        this.loading = false;
      }
    },
  },
  watch: {},
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
    ...mapGetters("organization", ["selectedOrganization"]),
    steps() {
      const data = ["POS", "Informações"];
      return data;
    },
  },
  mounted() {
    this.$parent.$on("adopt-pos-modal", this.open);
  },
  props: {},
  created() {},
};
</script>

<style></style>
