<template>
  <div>
    <div class="d-flex align-center gap-2 align-center mb-2">
      <v-text-field
        v-model="search"
        label="Buscar POS"
        outlined
        dense
        hide-details
        clearable
        append-icon="mdi-magnify"
        placeholder="Busque por nome ou serial number"
      />
      <v-btn v-if="hasPermission(268435456)" @click="addPos" color="primary">
        <v-icon left>mdi-plus</v-icon> vincular
      </v-btn>
    </div>
    <pos-totp-token />
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
      class="mt-2"
    />
    <v-alert v-else-if="!loading && error" class="mb-0" type="error">
      {{ error }}
    </v-alert>

    <v-row dense class="mx-0">
      <v-col
        v-for="session in filteredPos"
        :key="session.id"
        cols="12"
        md="6"
        lg="4"
      >
        <v-card
          class="pa-3 h-full d-flex flex-column justify-space-between"
          rounded="lg"
          outlined
        >
          <div class="d-flex gap-2">
            <v-img
              v-if="session.Device.Model?.image"
              :src="
                (session.Device?.acquirer &&
                  session.Device?.Model?.imageVariants?.[
                    session?.Device?.acquirer
                  ]) ||
                session.Device.Model.image
              "
              max-height="60"
              max-width="60"
            >
              <v-tooltip top v-if="!session.active">
                <template v-slot:activator="{ on }">
                  <v-chip v-on="on" small class="pa-1" color="warning">
                    <v-icon small color="white"> mdi-connection </v-icon>
                  </v-chip>
                </template>
                Desconectado
              </v-tooltip>
            </v-img>
            <v-icon v-else color="primary" size="60">
              mdi-cash-register
            </v-icon>
            <div class="d-flex flex-grow-1 flex-column justify-center">
              <div class="d-flex">
                <div style="display: grid" class="flex-grow-1">
                  <h6 class="mb-0 text-truncate-2-lines">
                    {{ session.name || session.Address?.name }}
                  </h6>
                </div>
                <v-btn
                  text
                  @click="editPos(session)"
                  small
                  v-if="hasPermission(268435456)"
                >
                  <v-icon small left>mdi-pencil</v-icon>
                  Editar
                </v-btn>
              </div>
              <span>SN: {{ session.Device.serialNumber }}</span>
            </div>
          </div>
          <div class="d-flex flex-wrap gap-1 mt-1">
            <v-alert color="primary" text small dense class="pa-1 mb-0">
              <div class="d-flex align-center gap-1 primary--text">
                <template v-for="method in session.allowPaymentMethods">
                  <v-icon
                    v-if="paymentMethods[method].icon"
                    :key="method"
                    color="primary"
                    size="20"
                  >
                    {{ paymentMethods[method].icon }}
                  </v-icon>
                  <div
                    v-else
                    v-html="paymentMethods[method].image"
                    class=""
                    :key="method"
                    style="width: 16px; height: 16px"
                  ></div>
                </template>
              </div>
            </v-alert>
            <v-alert
              v-if="session.requireClientRegistration"
              color="primary"
              class="pa-1 mb-0 text-13"
              text
              small
              dense
            >
              <v-icon class="mr-1" small color="primary">mdi-account-plus</v-icon>
              Requer Cadastro
            </v-alert>
            <v-alert
              v-if="session.noFee"
              color="primary"
              class="pa-1 mb-0 text-13"
              text
              small
              dense
            >
              <v-icon class="mr-1" small color="primary">mdi-currency-usd-off</v-icon>
              Sem taxas
            </v-alert>
          </div>
          <div class="d-flex gap-2 mt-3">
            <v-btn
              color="primary"
              small
              style="flex: 1"
              @click="posReport(session)"
              v-if="hasPermission(536870912)"
            >
              <v-icon left small>mdi-cash-register</v-icon>
              Relatório
            </v-btn>
            <v-btn
              color="primary"
              small
              style="flex: 1"
              @click="cashoutPos(session)"
              v-if="hasPermission(1073741824)"
            >
              <v-icon left small>mdi-cash-refund</v-icon>
              Sangria
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <cashout-pos @success="getPos" />
    <adopt-pos @success="getPos" />
    <edit-adopt-pos @success="getPos" />
    <pos-report />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import POS from "@/services/admin/pos";

import PosTotpToken from "./PosTotpToken.vue";
import CashoutPos from "./modal/CashoutPos.vue";
import AdoptPos from "./modal/AdoptPos.vue";
import EditAdoptPos from "./modal/EditAdoptPos.vue";
import PosReport from "./modal/PosReport.vue";
const pixIcon = require("@/assets/images/payment-card/pix-icon.svg");
export default {
  components: {
    PosTotpToken,
    CashoutPos,
    AdoptPos,
    EditAdoptPos,
    PosReport,
  },
  data: () => ({
    sessions: [],
    loading: true,
    error: false,
    search: "",
    paymentMethods: {
      money: {
        title: "Dinheiro",
        icon: "mdi-cash",
      },
      pix: {
        title: "Pix",
        image: null,
      },
      credit: {
        title: "Cartão de crédito",
        icon: "mdi-credit-card",
      },
      debit: {
        title: "Cartão de débito",
        icon: "mdi-credit-card-outline",
      },
      courtesy: {
        title: "Crédito",
        icon: "mdi-account-star",
      },
    },
  }),

  methods: {
    async getPos(force = false) {
      if (this.loading && !force) return;
      this.loading = true;
      try {
        const orgId = this.selectedOrganization.id;
        const { sessions } = await POS.getAll(orgId);
        this.sessions = sessions;
      } catch (error) {
        this.error = error.message || "Erro ao buscar os POS";
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    addPos() {
      this.$emit("adopt-pos-modal");
    },
    posReport(pos) {
      this.$emit("pos-report-modal", pos);
    },
    editPos(pos) {
      this.$emit("edit-adopt-pos-modal", pos);
    },
    cashoutPos(pos) {
      this.$emit("cashout-pos-modal", pos);
    },
    async loadPixIcon() {
      try {
        const response = await fetch(pixIcon);
        this.paymentMethods.pix.image = await response.text();
      } catch (error) {
        console.error("Erro ao carregar o SVG:", error);
      }
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    ...mapGetters("auth", ["hasPermission"]),
    filteredPos() {
      const search = this.search.toLowerCase();
      return this.sessions.filter((session) => {
        const name = session.name || session.Address?.name || "";
        if (name.toLowerCase().includes(search)) return true;
        if (session.Device.serialNumber.toLowerCase().includes(search))
          return true;

        return false;
      });
    },
  },
  watch: {
    selectedOrganization(val, oldVal) {
      if (val?.id !== oldVal?.id) {
        this.sessions = [];
        this.getPos();
      }
    },
  },
  mounted() {
    this.loadPixIcon();
    this.getPos(true);
    if (this.$route.query.code) {
      this.$emit("adopt-pos-modal", this.$route.query.code);
      this.$router.replace({ query: {} });
    }
  },
};
</script>

<style>
.text-truncate-2-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}
</style>
